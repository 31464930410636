/**
 * @generated SignedSource<<a849e743b4920d8223d33f571dd94588>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedYMAL_itemSearch$data = {
  readonly pageType: string | null;
  readonly youMayAlsoLike: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly item: {
          readonly serviceId: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"SharedMobileRelatedItem_item" | "SharedRespRelatedItem_item" | "sharedRelatedItemsWrapperTracking_item">;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "SharedYMAL_itemSearch";
};
export type SharedYMAL_itemSearch$key = {
  readonly " $data"?: SharedYMAL_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedYMAL_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "priceBookName",
    "variableName": "priceBookName"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceBookName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedYMAL_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        }
      ],
      "concreteType": "youMayAlsoLikeConnection",
      "kind": "LinkedField",
      "name": "youMayAlsoLike",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "youMayAlsoLikeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ItemSearchResult",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Item",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "args": (v1/*: any*/),
                      "kind": "FragmentSpread",
                      "name": "SharedMobileRelatedItem_item"
                    },
                    {
                      "args": (v1/*: any*/),
                      "kind": "FragmentSpread",
                      "name": "SharedRespRelatedItem_item"
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "sharedRelatedItemsWrapperTracking_item",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "contemporaryTrackingString",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "browseUrl",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "categoryCode",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Seller",
                          "kind": "LinkedField",
                          "name": "seller",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "shippingCostHelpers_item",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "vertical",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ShipmentQuote",
                              "kind": "LinkedField",
                              "name": "shipmentQuotes",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isComplimentary",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isCalculated",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "FinancialAmount",
                                  "kind": "LinkedField",
                                  "name": "totalAmount",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "ConvertedAmounts",
                                      "kind": "LinkedField",
                                      "name": "convertedAmounts",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "USD",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ShipmentServiceMethod",
                                  "kind": "LinkedField",
                                  "name": "serviceMethod",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Region",
                                      "kind": "LinkedField",
                                      "name": "region",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "regionName",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "youMayAlsoLike(first:20)"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "47a3ed8bc918a5c64a856766e3aeaa6b";

export default node;
